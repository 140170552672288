import {
  useEffect,
  useRef,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Image
} from "@chakra-ui/react";
import {
  Leaf,
  PiggyBank,
  Home,
  BarChart3,
  ChevronRight,
  ChevronDown
} from "lucide-react";
import PreferencesModal from "src/components/shared/PreferencesModal";
import { useIsDesktop } from "src/hooks/useIsDesktop";
import { FaHome } from "react-icons/fa";

import { getIsValidPostcode } from "src/helpers";
import { routes } from "src/constants";

const options = [
  {
    slug: "goGreen",
    label: "Go Green",
    icon: Leaf,
    color: "text-emerald-600"
  },
  {
    slug: "savedMoney",
    label: "Save Money",
    icon: PiggyBank,
    color: "text-sky-600"
  },
  {
    slug: "upgradeFutureProof",
    label: "Upgrade & Futureproof",
    icon: Home,
    color: "text-violet-600"
  },
  {
    slug: "becomeCompliant",
    label: "Boost EPC",
    icon: BarChart3,
    color: "text-amber-600"
  }
];

export const HeroSection: React.FC = () => {
  const history = useHistory();
  const [ postcode, setPostcode ] = useState("");
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [ isImageLoaded, setIsImageLoaded ] = useState(false);

  const [ selectedPriorities, setSelectedPriorities ] = useState(
    localStorage.getItem("priorities-v1") ?
      Object.keys(JSON.parse(localStorage.getItem("priorities-v1")!))
        .filter(key => JSON.parse(localStorage.getItem("priorities-v1")!)[ key ] === true) :
      []
  );

  const [ selectedBudget, setSelectedBudget ] = useState(null);
  const [ selectedDisruption, setSelectedDisruption ] = useState(null);
  const defaultImgUrl = `https://genous-public-assets.s3.eu-west-2.amazonaws.com/public/landing/${useIsDesktop() ? "house.png" : "house-mobile.jpg"}`;

  useEffect(() => {
    localStorage.setItem("priorities-v1", JSON.stringify(
      options.reduce((acc, option) => {
        acc[ option.slug ] = selectedPriorities.includes(option.slug);

        return acc;
      }, {} as Record<string, boolean>)
    ));
  }, [ selectedPriorities ]);

  const onChangePrioritySelection = (prioritySlug: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedPriorities([ ...selectedPriorities, prioritySlug ]);
    } else {
      setSelectedPriorities(selectedPriorities.filter(p => p !== prioritySlug));
    }
  };

  const handleChangeBudget = option => {
    setSelectedBudget(option);
  };

  const handleChangeDisruption = option => {
    setSelectedDisruption(option);
  };

  const startImpactReview = () => {
    if (getIsValidPostcode(postcode)) {
      localStorage.setItem("postcode", postcode);
      history.push(routes.section(1));
    } else {
      alert("Please enter a valid UK postcode");
    }
  };

  return (
    <Box className="min-h-screen relative">
      <Box
        overflow="hidden"
        position="absolute"
        zIndex={0}
        top={0}
        left={-400}
        right={0}

        bottom={0}
        display={{
          base: "block",
          xl: "block"
        }}
      >
        <Image
          src={defaultImgUrl}
          display={{
            base: "none",
            md: "block"
          }}
          onLoad={() => setIsImageLoaded(true)}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            opacity: isImageLoaded ? 0.5 : 0,
            transition: "opacity 0.5s ease-in",
            maskImage: "linear-gradient(145deg, transparent 40%, black 50%, rgba(0, 0, 0, 0.7) 100%, transparent 100%)",
            WebkitMaskImage: "linear-gradient(145deg, transparent 40%, black 50%, rgba(0, 0, 0, 0.7) 100%, transparent 100%)",
            transform: "translateX(400px)"
          }}
        />

      </Box>

      <Box
        as="main"
        maxW="700"
        className="container mx-auto px-4 py-12 md:py-16 relative z-10"
      >
        <Box className="mx-auto max-w-3xl text-center space-y-6">
          <h1 className="text-3xl md:text-6xl font-bold">
            <><GradientText
              gradient="linear-gradient(120deg, #a000ff, #df5508)"
              text="Simply Genous."
              />
            </>
          </h1>

          <p className="text-lg md:text-2xl text-slate-700 max-w-2xl mx-auto leading-relaxed font-medium">
            Make your home more energy efficient and environmentally friendly and drive down your bills with the UK's premier home retrofit company
          </p>

          <Box
            className="
            shadow-xl
              rounded-3xl p-8 space-y-4
              bg-gradient-to-b from-slate-50 to-white
              backdrop-blur-lg
              relative
              overflow-hidden
              animate-gradient-border
            "
          >
            <Box
              className="
                absolute inset-0
                p-[1px] rounded-3xl
                bg-gradient-to-r from-transparent via-white/50 to-transparent
                animate-[shine_3s_ease-in-out_infinite]
                pointer-events-none
                z-[-1]
              "
            />

            <Box className="space-y-4">
              <h2 className="font-bold text-base md:text-lg text-black flex items-center justify-center gap-2">
                Home Check
                <FaHome />
              </h2>

              <p className="text-sm md:text-base text-slate-600 leading-relaxed hidden md:block">
                Take our free 5-minute Home Check to explore ways to reduce your carbon emissions and save energy and money. What's more, our home decarbonisation experts are on hand to take care of everything for you.
              </p>

            </Box>

            <h2 className="text-base md:text-lg font-medium text-black py-0 my-0">
              Let's start by understanding your priorities
            </h2>

            <Box
              minWidth={{
                base: "100%",
                lg: "450px"
              }}
              className="p-2 md:p-4 my-0 w-full md:inline-block md:w-[60%] md:bg-slate-50/80 shadow-sm md:border-slate-200 rounded-xl"
            >
              <Box className="grid gap-4 max-w-md mx-auto mt-2">
                {options.map(({
                  icon: Icon, label, color, slug
                }) => (
                  <Box
                    key={slug}
                    className="flex items-center justify-between"
                  >
                    <Box className="flex items-center gap-3">
                      <Icon className={`w-5 h-5 ${color}`} />

                      <label
                        htmlFor={slug}
                        className="text-slate-700 font-medium cursor-pointer whitespace-nowrap"
                      >
                        {label}
                      </label>
                    </Box>

                    <Box className="relative inline-flex items-center">
                      <label className="flex cursor-pointer">
                        <input
                          type="checkbox"
                          id={slug}
                          checked={selectedPriorities.includes(slug)}
                          onChange={e => {
                            onChangePrioritySelection(slug, e.target.checked);
                          }}
                          className="sr-only peer"
                        />

                        <Box
                          className="w-11 h-6 bg-slate-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-slate-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"
                          onClick={e => {
                            e.preventDefault();
                            const input = document.getElementById(slug) as HTMLInputElement;

                            input.checked = !input.checked;
                            onChangePrioritySelection(slug, input.checked);
                          }}
                        />
                      </label>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box className="text-center">

                <PreferencesModal
                  selectedBudget={selectedBudget}
                  selectedDisruption={selectedDisruption}
                  handleChangeBudget={handleChangeBudget}
                  handleChangeDisruption={handleChangeDisruption}
                />

              </Box>
            </Box>

            <Box
              className="flex flex-col sm:flex-row gap-3 max-w-md mx-auto items-center"
            >
              <input
                type="text"
                value={postcode}
                onChange={e => setPostcode(e.target.value)}
                onKeyPress={e => e.key === "Enter" && startImpactReview()}
                placeholder="Enter your postcode"
                className="flex-1 px-4 py-2.5 text-base border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent placeholder:text-slate-400 w-full h-12"
              />

              <button
                onClick={startImpactReview}
                className="w-full sm:w-auto px-6 py-3 text-lg font-semibold  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all duration-200 flex items-center justify-center  hover:shadow-lg transform hover:-translate-y-0.5 relative before:absolute before:inset-0 before:rounded-md before:p-[2px] bg-slate-800 text-white  before:-z-10  whitespace-nowrap h-12"
              >
                Get Started
                <ChevronRight className="ml-2 h-5 w-5" />
              </button>
            </Box>

            <Box className="text-center mt-6 pb-4">
              <button
                onClick={() => {
                  // scroll to below section
                  const rect = sectionRef.current?.getBoundingClientRect();

                  window.scrollTo({
                    top: rect ? rect.y + rect.height :
                    window.scrollY + window.innerHeight + 80,
                    behavior: "smooth"
                  });
                }}
                className="mt-2 pb-1 font-medium flex items-center gap-2 mx-auto transition-colors hover:text-slate-600"
              >
                Learn about the Genous difference
                <ChevronDown className="h-4 w-4" />
              </button>
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

const GradientText = ({ text, gradient }: {text: string; gradient: string}) => {
  const style = {
    background: gradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    display: "inline-block",
    textShadow: "0 2px 10px rgba(79, 70, 229, 0.15)"
  };

  return <span style={style}>{text}</span>;
};
