import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import { routes } from "src/constants";

const linkStyles = { ":hover": { textDecoration: "underline" } };

const DesktopNavigation: React.FC = () => {
  return (
    <Box
      as="nav"
      w="70%"
      mx={5}
      py={2}
      whiteSpace="nowrap"
      fontWeight="semibold"
    >
      <Flex
        pt={1}
        alignItems="center" gap={5}
      >
        <Link to={routes.aboutGenous} style={linkStyles}>About Us</Link>

        <Link to={routes.reduce} style={linkStyles}>Reduce Your Carbon</Link>

        <Link to={routes.howItWorks} style={linkStyles}>How It Works</Link>

        <Link to="/partners" style={linkStyles}>Partners</Link>

        <Link to={routes.insightsNewsHub} style={linkStyles}>Insights</Link>

        <Link to={routes.faqs} style={linkStyles}>FAQs</Link>

      </Flex>
    </Box>
  );
};

export default DesktopNavigation;