import Auth from "@aws-amplify/auth";
import {
  HStack,
  Spacer,
  Button as ChakraButton,
  Drawer,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Link
} from "@chakra-ui/react";
import React, {
  useEffect,
  useState
} from "react";
import {
  Link as RouteLink,
  useHistory
} from "react-router-dom";
import { useLocation } from "react-router";
import {
  Stories,
  useStoryblokApi
} from "@storyblok/react";
import { isStage } from "src/helpers/stage";
import {
  FaAngleRight,
  FaArrowAltCircleRight,
  FaArrowAltCircleUp,
  FaChevronCircleRight,
  FaHome,
  FaUnlock,
  FaUser
} from "react-icons/fa";
import { ArrowRight } from "lucide-react";
import { motion } from "framer-motion";

import { useAuthContext } from "../../hooks/useAuthContext";
import { useIsDesktop } from "../../hooks/useIsDesktop";
import { Wrapper } from "../shared/Wrapper";
import theme from "../theme";
import { Logo } from "../shared/Logo";
import { routes } from "../../constants";
import {
  MenuLink,
  MenuNavLink,
  MenuParentNavLink
} from "./MenuLinks";
import { NavMenuButton } from "./NavMenuButton";
import { NavContainer } from "./NavContainer";
import DesktopNavigation from "./DesktopNavigation";

export const NavBar: React.FC = () => {
  const { authDataState } = useAuthContext();
  const [ username, setUsername ] = useState("");
  const { authenticated } = authDataState ?? {};
  const isDesktop = useIsDesktop();
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    isOpen, onOpen, onClose
  } = useDisclosure();

  useEffect(() => {
    if (authenticated && !username) {
      Auth.currentAuthenticatedUser().then(user => {
        setUsername(`${user.attributes[ "custom:firstName" ]}`);
      });
    }
  }, [ authenticated, username ]);

  const sbApi = useStoryblokApi();
  const [ usefulInformationPages, setUsefulInformationPages ] = useState<Stories>();

  useEffect(() => {
    async function fetchData() {
      const pages = await sbApi.getStories({
        version: isStage("production") ? "published" : "draft",
        starts_with: "useful-information/",
        per_page: 100
      });

      setUsefulInformationPages(pages);
    }

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavContainer>
      <Wrapper
        display="flex"
        alignItems={{ base: "center" }}
        position="relative"
        bg="transparent"
        h="inherit"
        padding={theme.spacing[ 3 ]}
        variant="layout"
      >
        <HStack
          spacing={[ theme.spacing[ 4 ], theme.spacing[ 6 ] ]}
          alignItems="center"
          w="100%"
        >
          {!isDesktop && <NavMenuButton onClick={onOpen} />}

          <Link width={100} as={RouteLink} to={routes.home}>
            <Logo />
          </Link>

          {isDesktop ? (
            <DesktopNavigation />
          ) : (
            <Drawer
              isOpen={isOpen}
              placement='left'
              onClose={onClose}
              size="xs"
            >
              <DrawerContent
                fontWeight="500"
                letterSpacing="-0.01em"
                padding={theme.spacing[ 10 ]}
              >
                <DrawerCloseButton />

                <VStack align="left" spacing={theme.spacing[ 4 ]}>
                  {authenticated ? (
                    <MenuNavLink
                      to={routes.footprint}
                      title="View Home Check"
                      onCloseMenu={onClose}
                    />
                  ) : (
                    <MenuLink
                      title="Start Home Check"
                      to={routes.welcome}
                      onCloseMenu={onClose}
                    />
                  )}

                  {!authenticated && (
                    <MenuNavLink
                      to={routes.login}
                      title="Login"
                      onCloseMenu={onClose}
                    />
                  )}

                  <VStack align="left">
                    <MenuParentNavLink
                      parentProps={{
                        title: "About Us",
                        to: routes.aboutGenous,
                        onCloseMenu: onClose
                      }}
                      subItems={[
                        {
                          title: "Meet the experts",
                          to: routes.expertsGenous,
                          onCloseMenu: onClose
                        },
                        {
                          title: "Our credentials",
                          to: routes.credentialsGenous,
                          onCloseMenu: onClose
                        }
                      ]}
                    />

                    <MenuParentNavLink
                      parentProps={{
                        title: "Reduce your carbon",
                        to: routes.reduce,
                        onCloseMenu: onClose
                      }}
                      subItems={[
                        {
                          title: "Home Insulation",
                          to: "/reduce-your-carbon/home-insulation",
                          onCloseMenu: onClose
                        },
                        {
                          title: "Solar Panels",
                          to: routes.reduceSection("solar-panels"),
                          onCloseMenu: onClose
                        },
                        {
                          title: "Renewable Heating",
                          to: routes.reduceSection("renewable-heating"),
                          onCloseMenu: onClose
                        },
                        {
                          title: "Heating Controls",
                          to: "/reduce-your-carbon/heating-controls",
                          onCloseMenu: onClose
                        },
                        {
                          title: "Other Home Improvements",
                          to: "/reduce-your-carbon/other-home-improvements",
                          onCloseMenu: onClose
                        },
                        {
                          title: "Electric Vehicles",
                          to: routes.reduceSection("electric-vehicles"),
                          onCloseMenu: onClose
                        },
                        {
                          title: "Green Energy",
                          to: routes.reduceSection("green-energy"),
                          onCloseMenu: onClose
                        },
                        {
                          title: "Carbon Offsets",
                          to: routes.reduceSection("carbon-offsets"),
                          onCloseMenu: onClose
                        }
                      ]}
                    />

                    <MenuNavLink
                      title="How It Works"
                      to={routes.howItWorks}
                      onCloseMenu={onClose}
                    />

                    <MenuNavLink
                      title="Partners"
                      to="/partners"
                      onCloseMenu={onClose}
                    />

                    <MenuParentNavLink
                      parentProps={{
                        title: "Insights",
                        to: routes.insightsNewsHub,
                        onCloseMenu: onClose
                      }}
                      subItems={[
                        {
                          title: "News Hub",
                          to: routes.insightsNewsHub,
                          onCloseMenu: onClose
                        },
                        {
                          title: "Case Studies",
                          to: routes.caseStudies,
                          onCloseMenu: onClose
                        },
                        {
                          title: "Blog",
                          to: routes.insightsOurBlog,
                          onCloseMenu: onClose
                        }
                      ]}
                    />

                    {/* {usefulInformationPages?.data.stories && (
                      <MenuParentNavLink
                        parentProps={{
                          title: "Useful information",
                          to: routes.usefulInformation,
                          onCloseMenu: onClose
                        }}
                        subItems={usefulInformationPages?.data.stories.map(page => {
                          return {
                            title: page.name,
                            to: routes.usefulInformationSection(page.slug),
                            onCloseMenu: onClose
                          };
                        })}
                      />
                    )}

                    <MenuNavLink
                      title="Case studies"
                      to={routes.caseStudies}
                      onCloseMenu={onClose}
                    />

                    <MenuNavLink
                      title="Contact us"
                      to={routes.contact}
                      onCloseMenu={onClose}
                    /> */}
                  </VStack>

                  <VStack align="left">
                    {authenticated ? (
                      <>
                        {/* <MenuNavLink
                                  to={routes.profile}
                                  title="Profile"
                                  onCloseMenu={onClose}
                                /> */}

                        <MenuLink
                          title="Sign out"
                          to={routes.home}
                          onClick={() => Auth.signOut()}
                          onCloseMenu={onClose}
                        />
                      </>
                    ) : ""}
                  </VStack>
                </VStack>
              </DrawerContent>
            </Drawer>
          )}

          <Spacer flex={1} />

          {authenticated ? (
            <HStack
              justifyContent="center"
              alignItems="center"
            >
              {isDesktop && (
                <ChakraButton
                  as={Link}
                  to="/"
                  onClick={() => Auth.signOut()}
                  fontSize={[ "xs", "sm" ]}
                  minW={{
                    base: "unset",
                    md: "10ch"
                  }}
                  color={theme.colors.gray[ 400 ]}
                  backgroundColor={theme.colors.white}
                  borderRadius={0}
                  _hover={{
                    bg: theme.colors.gray[ 100 ],
                    color: theme.colors.black
                  }}
                >
                  Sign out
                </ChakraButton>
              )}

              <ChakraButton
                as={RouteLink}
                to={routes.footprint}
                fontSize={[ "xs", "sm" ]}
                minW={{
                  base: "unset",
                  md: "10ch"
                }}
                borderWidth={1}
                borderColor={theme.colors.black}
                borderRadius={0}
                backgroundColor={theme.colors.white}
                _hover={{
                  bg: theme.colors.black,
                  color: theme.colors.white
                }}
              >
                View Home Check
              </ChakraButton>
            </HStack>
          ) : (
            <HStack
              justifyContent="center"
              alignItems="center"
            >
              {isDesktop && (
                <ChakraButton
                  as={RouteLink}
                  to={routes.retrieve}
                  fontSize="sm"
                  fontWeight="semibold"
                  minW={{
                    base: "unset",
                    md: "10ch"
                  }}
                  background="white"
                  borderRadius={0}
                  _hover={{ color: theme.colors.black }}
                >
                  Login
                </ChakraButton>
              )}

              {isDesktop && ![ "/section", "/register" ].some(p => pathname.startsWith(p)) && (
                <div className="flex items-center justify-center">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="relative group"
                    onClick={() => history.push(routes.welcome)}
                  >
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-emerald-300 to-purple-400 rounded-lg blur opacity-60 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt" />

                    <div className="relative whitespace-nowrap flex items-center gap-2 px-6 py-2 bg-white rounded-lg leading-none">
                      <span className="text-sm font-medium text-gray-700">Start Home Check</span>

                      <div className="group flex items-center">
                        <ArrowRight className="block lg:hidden w-4 h-4 text-gray-700 group-hover:translate-x-1 transition-transform" />
                      </div>
                    </div>
                  </motion.button>
                </div>
              )}

            </HStack>
          )}
        </HStack>
      </Wrapper>
    </NavContainer>
  );
};