import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Text,
  VStack
} from "@chakra-ui/react";
import theme from "src/components/theme";
import { FullSpace } from "src/components/shared/FullSpace";
import { Wrapper } from "src/components/shared/Wrapper";
import { ChevronRightIcon } from "@chakra-ui/icons";

const PartnersLinks: React.FC = () => {
  return (
    <FullSpace
      alignItems="flex-start"
      p={{
        base: 4,
        md: 0
      }}
    >
      <Wrapper>
        <VStack
          spacing={4}
          align="start"
          textAlign="left"
        >
          <Box>
            <Link to="/partners/installers">
              <Text
                fontSize="lg"
                fontWeight="bold"
                color="blue.400"
                background="linear-gradient(135deg, #4F46E5 0%, #7C3AED 50%, #2563EB 100%)"
                backgroundClip="text"
                WebkitBackgroundClip="text"
                WebkitTextFillColor="transparent"
                color="transparent"
                textShadow="0 2px 10px rgba(79, 70, 229, 0.15)"
                textDecoration="underline !important"
                display="flex"
                alignItems="center"
              >
                For Installers
                <ChevronRightIcon ml={2} />
              </Text>
            </Link>

            <Text
              fontSize="sm"
              color="gray.500"
            >
              Joining forces to transform home retrofit
            </Text>
          </Box>

          <Box>
            <Link to="/partners/lenders">
              <Text
                fontSize="lg"
                fontWeight="bold"
                color="blue.400"
                background="linear-gradient(135deg, #4F46E5 0%, #7C3AED 50%, #2563EB 100%)"
                backgroundClip="text"
                WebkitBackgroundClip="text"
                WebkitTextFillColor="transparent"
                color="transparent"
                textShadow="0 2px 10px rgba(79, 70, 229, 0.15)"
                textDecoration="underline"
                display="flex"
                alignItems="center"
              >
                For Lenders
                <ChevronRightIcon ml={2} />
              </Text>
            </Link>

            <Text
              fontSize="sm"
              color="gray.500"
            >
              Enabling Sustainable Home Upgrades through Finance Solutions
            </Text>
          </Box>

          <Box>
            <Link to="/partners/architects">
              <Text
                fontSize="lg"
                fontWeight="bold"
                color="blue.400"
                background="linear-gradient(135deg, #4F46E5 0%, #7C3AED 50%, #2563EB 100%)"
                backgroundClip="text"
                WebkitBackgroundClip="text"
                WebkitTextFillColor="transparent"
                color="transparent"
                textShadow="0 2px 10px rgba(79, 70, 229, 0.15)"
                textDecoration="underline"
                display="flex"
                alignItems="center"
              >
                For Architects
                <ChevronRightIcon ml={2} />
              </Text>
            </Link>

            <Text
              fontSize="sm"
              color="gray.500"
            >
              Tailored retrofit solutions for Architects
            </Text>
          </Box>
        </VStack>
      </Wrapper>
    </FullSpace>
  );
};

export default PartnersLinks;