import { RouteComponentProps } from "react-router";

import { FullSpace } from "../../components/shared/FullSpace";
import { Wrapper } from "../../components/shared/Wrapper";
import theme from "../../components/theme";
import ContactPage from "../contact/ContactPage";
/**
 * Converting GDoc to react:
 *  1. https://www.gdoctohtml.com/
 *  2. https://magic.reactjs.net/htmltojsx.htm
 *  3. Manually (boo) fix the unordered lists (<ol>)
 *
 * I've applied some simple spacing styles via the wrapper parent
 */

/**
 *
 */

export const ArchitectsPage: React.FC<RouteComponentProps> = () => {
  return (
    <FullSpace>
      <Wrapper
        variant="text"
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 4 ]
        }}
        lineHeight="1.5"
        fontSize={theme.fontSizes.sm}
        sx={{
          "h1": {
            fontSize: {
              base: "2.5rem",
              md: "3rem"
            },
            fontWeight: "700",
            lineHeight: "1.2",
            mb: "0.5em",
            background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 50%, #2563EB 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent",
            textShadow: "0 2px 10px rgba(79, 70, 229, 0.15)"
          },
          "h2": {
            fontSize: {
              base: "1.8rem",
              md: "2.2rem"
            },
            fontWeight: "600",
            lineHeight: "1.3",
            mt: "2em",
            mb: "1em"
          },
          "h3": {
            fontSize: {
              base: "1.4rem",
              md: "1.6rem"
            },
            fontWeight: "600",
            lineHeight: "1.4",
            mt: "1.5em",
            mb: "0.75em"
          },
          "p": {
            my: "1.2em",
            lineHeight: "1.8",
            fontSize: {
              base: "1rem",
              md: "1.1rem"
            }
          },
          "ol, ul": {
            my: "1.5em",
            pl: "1.5em"
          },
          "li": {
            my: "1em",
            lineHeight: "1.6",
            fontSize: {
              base: "1rem",
              md: "1.1rem"
            }
          },
          "ol > li": { mt: "2em" },
          "strong": {
            color: "gray.700",
            display: "block",
            mb: "0.5em",
            fontSize: {
              base: "1.1rem",
              md: "1.2rem"
            }
          },
          "a": {
            color: "blue.600",
            textDecoration: "underline",
            _hover: { color: "blue.700" }
          }
        }}
      >
        <h1>Tailored retrofit solutions for Architects</h1>

        <h3>From concept to completion: your trusted partner in renewables and energy efficiency</h3>

        <p>Genous provides the Architect's one-stop-shop for all projects requiring renewable energy
          solutions and specialist retrofit support.
        </p>

        <p>We are the UK's premier home retrofitting company, committed to creating sustainable,
          energy-efficient solutions for homes of all types – and with particular expertise in complex
          situations. We work with you and your client using our unique online technology and
          experienced advisers, taking care of everything from project inception, surveys and
          specification – and provide service all the way through to final installation and completion.
        </p>

        <p>By partnering closely with architects, we bring a unique blend of technical expertise and
          innovative design to every project, ensuring that your vision for high-performance buildings
          becomes a reality.
        </p>

        <p>What's more, Genous only offers the best products (the Genous Gold heat pump, for instance,
          is the quietest and most efficient in the UK) for various different applications and price-
          points, and we keep up-to-date on the best new products but filter these through years of
          experience of installations.
        </p>

        <h3>Why Architects Choose Us</h3>

        <h4>Collaborative Approach</h4>

        <p>We specialize in providing cutting-edge renewable heating and energy efficiency solutions
          that seamlessly integrate with architectural designs. Working closely with architects, we
          ensure that each project—from small properties to the largest homes - benefit from energy-
          efficient, sustainable, and cost-effective systems.
        </p>

        <p>We understand the unique requirements of each project, working hand-in-hand with you to
          ensure that renewable heating systems are perfectly aligned with the design vision and
          technical specifications. Whether you're designing a contemporary home, a heritage
          renovation, or a sustainable new build, we provide expert guidance every step of the way.
        </p>

        <h4>Unrivalled technology</h4>

        <p>Genous's digital twinning technology allows us to build a digital model of the future
          building and test the implications of different energy efficiency technologies (insulation,
          glazing and renewables) to see how they interact and the relative savings, allowing you to
          prioritise the most cost-effective solutions for your clients.
        </p>

        <h4>Comprehensive Service</h4>

        <p>From the initial planning stages through to final installation and ongoing support, our team is
          here to make the process as smooth and efficient as possible. We'll help you navigate the
          complexities of integrating renewable technologies, ensuring compliance with regulations,
          and selecting the most suitable system for each project.
        </p>

        <h4>Customised Solutions</h4>

        <p>We don't believe in one-size-fits-all. Our renewable systems are designed to meet the unique
          needs of each space. We offer bespoke solutions for heat pumps, underfloor heating and
          radiator upgrades, solar PV, EV charging and more, ensuring optimal performance and energy
          efficiency.
        </p>

        <h4>Example feedback</h4>

        <p>
          Genous’s approach is scientific and methodical, which enables our clients to understand their options and the return on investment, along with clear ideas on how to create an environmentally friendly, reduced carbon footprint space. The team’s communication is comprehensive, and we have found them proactive to collaborate with. Genous are consultative with our clients, a key focus point for our own business model when working on developments. (Nicholas Stockley, Gounder, Design Squared Architects)
        </p>

        <h3>Our Process</h3>

        <ol>
          <li>
            <strong>Early Consultation</strong>

            <p>We can support you at the opening of a project, giving perspectives on likely solutions,
              challenges and constraints – this can often save time and rework later in the project
            </p>
          </li>

          <li>
            <strong>System Design & Planning</strong>

            <p>Our team can then create and iterate an energy efficiency solution, from initial parameters to
              detailed specification to ultimate compliant (MCS, Trustmark, RECC etc) quotes.
            </p>
          </li>

          <li>
            <strong>Expert Installation</strong>

            <p>We provide professional installation by qualified technicians who follow best practice to
              ensure the system is appropriately installed, commissioned and handed-over.
            </p>
          </li>

          <li>
            <strong>Ongoing Support</strong>

            <p>Our installers are able to offer continued support and maintenance to ensure your systems
              operate efficiently and deliver long-term performance.
            </p>
          </li>
        </ol>

        <h2>Ready to Collaborate?</h2>

        <p>Let's create something extraordinary together. Contact us today to discuss your upcoming
          projects and discover how we can help bring your architectural vision to life with sustainable
          energy solutions.
        </p>

        <p>Contact us at <a href="mailto:architect@genous.earth">architect@genous.earth</a> or fill in the form below</p>
      </Wrapper>

      {/* Contact form outside of the text wrapper for full width */}
      <Wrapper
        variant="wide"
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 12 ]
        }}
      >
        <ContactPage />
      </Wrapper>
    </FullSpace>
  );
};

export default ArchitectsPage;