import { RouteComponentProps } from "react-router";

import { FullSpace } from "../../components/shared/FullSpace";
import { Wrapper } from "../../components/shared/Wrapper";
import theme from "../../components/theme";
import ContactPage from "../contact/ContactPage";
/**
 * Converting GDoc to react:
 *  1. https://www.gdoctohtml.com/
 *  2. https://magic.reactjs.net/htmltojsx.htm
 *  3. Manually (boo) fix the unordered lists (<ol>)
 *
 * I've applied some simple spacing styles via the wrapper parent
 */

/**
 *
 */

export const LendersPage: React.FC<RouteComponentProps> = () => {
  return (
    <FullSpace>
      <Wrapper
        variant="text"
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 4 ]
        }}
        lineHeight="1.5"
        fontSize={theme.fontSizes.sm}
        sx={{
          "h1": {
            fontSize: {
              base: "2.5rem",
              md: "3rem"
            },
            fontWeight: "700",
            lineHeight: "1.2",
            mb: "0.5em",
            background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 50%, #2563EB 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent",
            textShadow: "0 2px 10px rgba(79, 70, 229, 0.15)"
          },
          "h2": {
            fontSize: {
              base: "1.8rem",
              md: "2.2rem"
            },
            fontWeight: "600",
            lineHeight: "1.3",
            mt: "2em",
            mb: "1em"
          },
          "h3": {
            fontSize: {
              base: "1.4rem",
              md: "1.6rem"
            },
            fontWeight: "600",
            lineHeight: "1.4",
            mt: "1.5em",
            mb: "0.75em"
          },
          "p": {
            my: "1.2em",
            lineHeight: "1.8",
            fontSize: {
              base: "1rem",
              md: "1.1rem"
            }
          },
          "ol, ul": {
            my: "1.5em",
            pl: "1.5em"
          },
          "li": {
            my: "1em",
            lineHeight: "1.6",
            fontSize: {
              base: "1rem",
              md: "1.1rem"
            }
          },
          "ol > li": { mt: "2em" },
          "strong": {
            color: "gray.700",
            display: "block",
            mb: "0.5em",
            fontSize: {
              base: "1.1rem",
              md: "1.2rem"
            }
          },
          "a": {
            color: "blue.600",
            textDecoration: "underline",
            _hover: { color: "blue.700" }
          }
        }}
      >
        <h1>Enabling Sustainable Home Upgrades through Finance Solutions</h1>

        <p>Genous is the UK's premier home retrofitting company, committed to creating sustainable,
          energy-efficient solutions for homes of all types. At Genous, we are committed to helping
          homeowners transition to a more sustainable future by offering cutting-edge retrofit solutions,
          including heat pumps, solar panels, EV chargers, smart tech and insulation and glazing.
        </p>

        <p>As a leading provider of end-to-end retrofit planning and delivery services in the able-to-pay
          residential market, we aim to make energy-efficient upgrades more accessible and affordable
          for households across the country.
        </p>

        <h2>The power of finance partnerships</h2>

        <p>We understand that financing is a crucial factor in enabling homeowners to invest in green
          technologies to make their homes more energy efficient and reduce their costs and carbon
          footprint. By working with banks, building societies, and green finance lenders, we are
          aiming to offer tailored solutions that make sustainable home upgrades more financially
          viable. Our partnerships aim to:
        </p>

        <ul>
          <li>Support Homeowners: Provide flexible and accessible finance options that empower
            homeowners to make eco-friendly upgrades with ease.
          </li>

          <li>Drive Sustainability: Promote the adoption of renewable energy solutions, helping to
            reduce carbon footprints and lower energy bills.
          </li>

          <li>Enhance Value: Retrofit solutions like heat pumps and solar panels not only improve
            home energy efficiency but should also underpin property values.
          </li>

          <li>Ensure Compliance: Align with government initiatives and green finance schemes to
            make home upgrades compliant with environmental standards.
          </li>
        </ul>

        <h2>Why collaborate with Genous?</h2>

        <ul>
          <li>We have the best, most flexible advisory technology in the UK, giving us better cost
            and savings estimates and supporting ESG information on what we recommend.
          </li>

          <li>We are totally independent and work across a wide selection of product providers,
            using only use the best products and interventions, ensuring both the lowest risk to the
            property (from inappropriate specification) and the highest performance/cost parameters.
          </li>

          <li>Our philosophy is based on giving the customers the best advice and recommending
            the right solutions for the customers budget and property needs.
          </li>

          <li>We treat customers' money like our own so don't recommend high cost/low return
            options unless the customer particularly wants them.
          </li>

          <li>We are a high-service business using the best installers and technologies to ensure that
            the customer experience is the best it can be. Doing business with us is easy for you and,
            because we contract directly with the customer except for very specialist interventions, it
            is also easy for your customers.
          </li>

          <li>We are MCS and Trustmark accredited, are members of RECC and have given evidence to
            the ESNZ Select Committee on consumer protection – we are also a purpose-led organisation
            and focused on providing the very best outcomes for customers at fair pricing.
          </li>
        </ul>

        <h2>We specialize in providing the following services to homeowners:</h2>

        <ul>
          <li>Heat Pumps: High-efficiency heating and cooling solutions for homes, reducing
            energy consumption and emissions.
          </li>

          <li>Solar Panels: Renewable energy systems that help homeowners harness the power of
            the sun and lower energy costs, with batteries and ancillaries (hot water power
            diverters, EV chargers, grid back-up) as required.
          </li>

          <li>Insulation and glazing: High-performance insulation and performance glazing
            solutions that ensures homes stay warm in winter and cool in summer, reducing the
            need for excessive heating and cooling.
          </li>

          <li>Smart tech: We can offer zonal and other specialist heating controls, smart hot water
            cylinders and SAP Appendix Q-compliant insulation technologies in addition to our
            core solutions.
          </li>
        </ul>

        <h2>Working together</h2>

        <p>We are looking to partner with financial institutions that share our vision for a greener future.
          Together, we can offer homeowners attractive financing options for sustainable home upgrades.
          Whether through green mortgages or other low-interest rate lending, or specialized payment plans,
          our goal is to help homeowners make the switch to a more energy-efficient lifestyle.
        </p>

        <h2>Benefits for Finance Partners:</h2>

        <ul>
          <li>Expand your portfolio by offering green financial products.</li>

          <li>Support sustainability initiatives and attract eco-conscious customers.</li>

          <li>Leverage our expertise in retrofit planning and installation for seamless project execution.</li>

          <li>Tap into a growing market of homeowners looking for eco-friendly solutions.</li>
        </ul>

        <h2>Get in Touch</h2>

        <p>If you are a bank, building society, or green finance lender interested in exploring partnership
          opportunities with Genous, we would love to hear from you. Together, we can make sustainable
          home upgrades more accessible to a wider audience.
        </p>

        <p>Contact us today to discuss how we can collaborate to create a greener future for homeowners.</p>

        <p>Contact us at <a href="mailto:lending@genous.earth">lending@genous.earth</a> or fill in the form below</p>
      </Wrapper>

      {/* Contact form outside of the text wrapper for full width */}
      <Wrapper
        variant="wide"
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 12 ]
        }}
      >
        <ContactPage />
      </Wrapper>
    </FullSpace>
  );
};

export default LendersPage;