import {
  FormControl,
  Heading,
  Input,
  InputProps,
  Stack,
  Textarea,
  useToast,
  VStack,
  Link as ChakraLink
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useEffect } from "react";
import {
  FieldError,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

import { useContactApi } from "../../api/contact/contact.api";
import { Button } from "../../components/button";
import { FullSpace } from "../../components/shared/FullSpace";
import { Wrapper } from "../../components/shared/Wrapper";
import { Text } from "../../components/text";
import theme from "../../components/theme";
import { breakpoints } from "../../components/theme/breakpoints";
import { routes } from "../../constants";
import {
  devLog,
  getUKTelephoneSchema
} from "../../helpers";
import { useBreakpointKeyValue } from "../../hooks/useBreakpointKeyValue";
import { desktopBreakpointKey } from "../../hooks/useIsDesktop";

const breakpointKeys = Object.keys(breakpoints);

export const ContactPage: React.FC<RouteComponentProps> = ({ history }) => {
  const toast = useToast();
  const [ response, request ] = useContactApi("CONTACT");
  const [ currentBreakpointKey ] = useBreakpointKeyValue();

  useEffect(() => {
    if (!response.pending && response.complete) {
      if (response.data) {
        toast({
          title: "Your message has been sent",
          description: "We will get back to you as soon as possible",
          isClosable: true,
          status: "success"
        });
        history.push(routes.home);
      } else if (response.error) {
        toast({
          title: "There was an error sending your message",
          description: response.error.message,
          isClosable: true,
          status: "error"
        });

        if (response.error.status !== 500) devLog(`Error from contact form request (${response.error.status})`, response.error);
      }
    }
  }, [
    history,
    response,
    toast
  ]);

  const {
    register, handleSubmit, formState: { errors }
  } = useForm<ContactForm>({ resolver: joiResolver<typeof schema>(schema) });

  const submitHandler: SubmitHandler<ContactForm> = data => {
    try {
      request(data);
    } catch (e) {
      toast({
        title: "Your message could not be sent",
        description: "Please try again later",
        isClosable: true,
        status: "error"
      });
      devLog("Error requesting form", e);
    }
  };

  return (
    <FullSpace bg={theme.colors.gray[ 100 ]}>

      <Wrapper
        // had a hard time trying to get a responsive variant object working -
        // specifically it was the types. Using indexOf to create a responsive
        // variant for now
        variant={breakpointKeys.indexOf(currentBreakpointKey) >= breakpointKeys.indexOf(desktopBreakpointKey) ? "layout" : "text"}
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 12 ]
        }}
      >
        <Stack
          direction={{
            base: "column",
            [ desktopBreakpointKey ]: "row"
          }}
          spacing={{
            base: theme.spacing[ 8 ],
            md: theme.spacing[ 12 ]
          }}
        >

          <VStack
            alignItems="flex-start"
            spacing="1em"
            flex={{
              "base": 1,
              "2xl": 1.33
            }}
          >
            <Heading
              fontWeight="medium"
              fontSize={theme.fontSizes.xl}
              color="black"
            >
              Contact Us
            </Heading>

            <Text maxW="60ch">
              Feel free to reach out to us, we’re happy to help you on your journey in reducing your carbon footprint. You may also find the answer to your query in our

              {" "}

              <ChakraLink
                as={Link}
                to={routes.faqs}
                color={theme.colors.gray[ "500" ]}
                textDecoration="underline"
              >
                FAQs
              </ChakraLink>
              .
            </Text>
          </VStack>

          <FormControl
            as="form"
            onSubmit={handleSubmit(submitHandler)}
            flex={{ base: 1 }}
          >
            <VStack alignItems="flex-start">
              <Stack
                direction={{
                  base: "column",
                  sm: "row"
                }}
                w="100%"
              >
                <Input
                  {...register("firstName")}
                  isRequired
                  placeholder="First name"
                />

                <Input
                  {...register("lastName")}
                  isRequired
                  placeholder="Last name"
                />
              </Stack>

              <Input
                {...register("email")}
                isRequired
                placeholder="Email"
                {...invalidInputStyleProps(errors.email)}
              />

              {renderError(errors.email)}

              <Input
                {...register("telephone")}
                placeholder="Phone number"
                {...invalidInputStyleProps(errors.telephone)}
              />

              {renderError(errors.telephone)}

              <Textarea
                {...register("message")}
                isRequired
                placeholder="Type your message.."
                resize="none"
                height="12ch"
                bg="white"
                border="none"
                fontSize={{
                  base: theme.fontSizes.sm,
                  sm: "base"
                }}
                sx={{ "--chakra-radii-md": "0" }}
              />

              <br />

              <Button
                type="submit"
                isDisabled={response.pending}
                isLoading={response.pending}
                alignSelf="flex-start"
              >
                Send
              </Button>
            </VStack>
          </FormControl>

        </Stack>
      </Wrapper>
    </FullSpace>
  );
};

export default ContactPage;

const schema = Joi.object<ContactForm>({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false } }).required(),
  telephone: getUKTelephoneSchema().optional().empty(""),
  message: Joi.string().required()
});

const renderError = (error?: FieldError) => {
  return error && (
    <Text
      color={theme.colors.orange}
      fontSize="sm"
    >
      {error.message}
    </Text>
  );
};

const invalidInputStyleProps = (error?: FieldError): InputProps => ({
  isInvalid: Boolean(error),
  errorBorderColor: theme.colors.orange,
  _invalid: {
    border: "none",
    boxShadow: `0 0 0 2px ${theme.colors.orange} !important`
  }
});