import { useAxios } from "../../hooks/useAxios";
import { JSONAPIResponse } from "../json-api-response";

// request + response types

type ContactEndpointConfig = {
  CONTACT: EndpointConfig<
    ContactForm,
    JSONAPIResponse<string>
  >;
};

const requestInitialisers: RequestConfig<ContactEndpointConfig> = {
  CONTACT: (
    formData => ({
      url: "/contact",
      method: "POST",
      data: formData
    })
  )
};

export const useContactApi: UseApiRequest<ContactEndpointConfig> = () => useAxios(requestInitialisers[ "CONTACT" ]);
