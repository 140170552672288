import { RouteComponentProps } from "react-router";

import { FullSpace } from "../../components/shared/FullSpace";
import { Wrapper } from "../../components/shared/Wrapper";
import theme from "../../components/theme";
import ContactPage from "../contact/ContactPage";
/**
 * Converting GDoc to react:
 *  1. https://www.gdoctohtml.com/
 *  2. https://magic.reactjs.net/htmltojsx.htm
 *  3. Manually (boo) fix the unordered lists (<ol>)
 *
 * I've applied some simple spacing styles via the wrapper parent
 */

/**
 *
 */

export const InstallersPage: React.FC<RouteComponentProps> = () => {
  return (
    <FullSpace>
      <Wrapper
        variant="text"
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 4 ]
        }}
        lineHeight="1.5"
        fontSize={theme.fontSizes.sm}
        sx={{
          "h1": {
            fontSize: {
              base: "2.5rem",
              md: "3rem"
            },
            fontWeight: "700",
            lineHeight: "1.2",
            mb: "0.5em",
            background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 50%, #2563EB 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent",
            textShadow: "0 2px 10px rgba(79, 70, 229, 0.15)"
          },
          "h2": {
            fontSize: {
              base: "1.8rem",
              md: "2.2rem"
            },
            fontWeight: "600",
            lineHeight: "1.3",
            mt: "2em",
            mb: "1em"
          },
          "h3": {
            fontSize: {
              base: "1.4rem",
              md: "1.6rem"
            },
            fontWeight: "600",
            lineHeight: "1.4",
            mt: "1.5em",
            mb: "0.75em"
          },
          "p": {
            my: "1.2em",
            lineHeight: "1.8",
            fontSize: {
              base: "1rem",
              md: "1.1rem"
            }
          },
          "ol, ul": {
            my: "1.5em",
            pl: "1.5em"
          },
          "li": {
            my: "1em",
            lineHeight: "1.6",
            fontSize: {
              base: "1rem",
              md: "1.1rem"
            }
          },
          "ol > li": { mt: "2em" },
          "ul ul": { // Styling for nested lists
            mt: "0.5em",
            mb: "1em",
            pl: "1.5em"
          },
          "ul ul li": { // Styling for nested list items
            my: "0.5em"
          },
          "strong": {
            color: "gray.700",
            display: "block",
            mb: "0.5em",
            fontSize: {
              base: "1.1rem",
              md: "1.2rem"
            }
          },
          "a": {
            color: "blue.600",
            textDecoration: "underline",
            _hover: { color: "blue.700" }
          }
        }}
      >
        <h1>Joining forces to transform home retrofit</h1>

        <p>Genous is the UK's premier home retrofitting company, committed to creating sustainable,
          energy-efficient solutions for homes of all types. We focus on the able-to-pay segment and
          provide a one-stop-shop for customers across insulation & glazing, solar and heat pumps,
          smart tech and more.
        </p>

        <p>Our aim is to provide a seamless, end-to-end service for our customers using our unique
          technology, highly experienced support staff and out UK-wide installer network.
        </p>

        <p>We use a subcontracted delivery model and have no aspiration to do installation work
          ourselves. What we do is to manage the customer acquisition and specification approach and
          we contract with the customer directly, carrying out renewables work in particular under our
          own MCS accreditation. This is about providing customers with the best advice, the best
          solutions and the best service.
        </p>

        <p>We don't 'auction' out our work – we want to partner with the right providers in the right
          geographies for the right products – and we are always looking out for partners who share our
          values and work at the highest quality levels.
        </p>

        <h2>The areas that we are looking for partners in include:</h2>

        <ul>
          <li>
            <strong>Insulation</strong>

            <ul>
              <li>Most of our work is cavity wall, loft insulation, flat roof insulation and
                structural roof-room insulation
              </li>

              <li>We also do some floor insulation (mainly suspended but some solid floor),
                solid wall insulation (internal and external) and other specialist insulation
              </li>

              <li>We do not work with spray-foam and expect all providers to operate at the
                highest standards and be members of all relevant accreditation/insurance
                schemes (e.g. CIGA/SWIGA)
              </li>
            </ul>
          </li>

          <li>
            <strong>Solar PV</strong>

            <ul>
              <li>We prefer to work with providers who offer the full range of options (pitched
                and flat roofs + ground-mount) and are comfortable offering the add-ons below
              </li>

              <li>We also provide home batteries, hot water power diverters, EV chargers and
                other ancillaries, but only in combination with PV so specialists in this area
                are not needed
              </li>
            </ul>
          </li>

          <li>
            <strong>Heat pumps</strong>

            <ul>
              <li>Our focus is on air-source heat pumps and we expect installers to be familiar
                with different schematics (including open loop and multi-zone systems),
                controllers and heat pump brands
              </li>

              <li>We rarely install ground-source HPs or biomass</li>
            </ul>
          </li>

          <li>
            <strong>Other</strong>

            <ul>
              <li>We are open to providers with capabilities in other areas including
                performance glazing, draughtproofing and specialist services into traditional
                buildings
              </li>
            </ul>
          </li>
        </ul>

        <h2>Why installers work with us</h2>

        <h3>Collaborative Approach</h3>

        <p>We want to be our installers' best customers, and to support you to grow your business. That
          means fair payment terms (and pricing) and giving you the type of business that suits you and
          is local to you.
        </p>

        <h3>Whole home retrofit benefits</h3>

        <p>Our ability to work across the retrofit intervention set means the ability to optimise an install
          by bringing other technologies. We won't sell a heat pump install to a customer who should
          really insulate first (except in specific situations) and our ability to combine technologies
          means that what is installed should work well together.
        </p>

        <h3>Incremental attractive business</h3>

        <p>We are not a lead-generation provider but we pass on work-ready projects that have been
          qualified, specified and sold. We pay for up-front technical visits and we take the hassle of
          customer payments away. There isn't an easier way to increase your business.
        </p>

        <h3>Reduced hassle and risk</h3>

        <p>We carry out the various compliance works for MCS using our state-of-the-art software, we
          do much of the customer education and product selection work and produce the core
          mandated documentations.
        </p>

        <p>This leaves you free to do the work and not worry about paperwork – though for the
          avoidance of doubt we will only contract with MCS-accredited providers (for heat pumps and
          solar) and expect you to contract to all relevant quality standards and to guarantee works to us
          to at least the standards expected of consumer-contracted works.
        </p>

        <h3>Best technology</h3>

        <p>As well as our advisory and digital home twinning capability, we only use the best
          technologies from a product perspective, giving you the ability to work with the highest
          quality brands. Note that we do specify technologies for our clients, so will require you to
          install what we say except in situations where product selection is less critical.
        </p>

        <h2>What are we looking for</h2>

        <ol>
          <li>
            <strong>Expertise and capability</strong>

            <p>We expect fair pricing but we are not interested in lowest cost provision – we are only
              interested in partnering with the very best installers.
            </p>
          </li>

          <li>
            <strong>Exceptional service</strong>

            <p>Customer service is generally poor in this industry. We expect more, and require suppliers to
              treat customers well, with regular, timely and appropriate communication, and 'white glove'
              service levels.
            </p>
          </li>

          <li>
            <strong>Collaboration</strong>

            <p>We treat our installers fairly and expect the same in return. Fair pricing, compliance with our
              terms and conditions and a proactive and supportive approach to us are all musts.
            </p>
          </li>

          <li>
            <strong>Compliance and ethics</strong>

            <p>We do not 'gouge' price and will not tolerate this in turn from our suppliers. We do not accept
              short-cuts and will only contract with appropriately accredited firms. We expect you to act in
              our and our customers' best interests at all times.
            </p>
          </li>
        </ol>

        <h2>Ready to Collaborate?</h2>

        <p>Let's create something extraordinary together. Contact us today to discuss what you do and
          see if there is a fit. Please note that we are only looking for installers and not manufacturers
          or lead generation partners.
        </p>

        <p>Contact us at <a href="mailto:installer@genous.earth">installer@genous.earth</a> or fill in the form below</p>
      </Wrapper>

      {/* Contact form outside of the text wrapper for full width */}
      <Wrapper
        variant="wide"
        py={{
          base: theme.spacing[ 8 ],
          md: theme.spacing[ 12 ]
        }}
      >
        <ContactPage />
      </Wrapper>
    </FullSpace>
  );
};

export default InstallersPage;