import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Text,
  HStack,
  useToast
} from "@chakra-ui/react";
import { HiOutlineCheck } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router";

import {
  budgetOptions,
  disruptionOptions
} from "src/constants";

import theme from "../theme";

const PreferencesModal = ({
  selectedBudget, handleChangeBudget, selectedDisruption, handleChangeDisruption
}) => {
  const history = useHistory();

  const {
    isOpen, onOpen, onClose
  } = useDisclosure();

  const toast = useToast();

  const ctaButtonStyles: ButtonProps = {
    variant: "outline",
    fontWeight: "inherit",
    borderRadius: "0",
    padding: "0",
    alignItems: "center",
    overflow: "hidden",
    iconSpacing: "0",
    // w: "100%",
    // maxW: {
    //   base: "100%",
    //   sm: "400px"
    // },
    justifyContent: "flex-start",
    border: "1px solid",
    borderColor: "black",
    height: "2em"
  };

  const handleSavePreferences = () => {
    if (selectedBudget) {
      localStorage.setItem("budget-v1", JSON.stringify(selectedBudget));
    }

    if (selectedDisruption) {
      localStorage.setItem("disruptionAttitude-v1", JSON.stringify(selectedDisruption));
    }

    onClose();

    toast({
      status: "success",
      title: "Preferences saved",
      description: "Thank you – your priority and budget choices have been saved!",
      duration: 3000,
      isClosable: true,
      position: "top-right"
    });
  };

  const getSelectedPreference = () => {
    const retrievedBudget =
      JSON.parse(localStorage.getItem("budget-v1"));

    const retrievedDisruption =
      JSON.parse(localStorage.getItem("disruptionAttitude-v1"));

    if (retrievedBudget) {
      handleChangeBudget(retrievedBudget);
    }

    if (retrievedDisruption) {
      handleChangeDisruption(retrievedDisruption);
    }
  };

  useEffect(() => {
    getSelectedPreference();
  }, []);

  return (
    <>
      <button
        onClick={() => onOpen()}
        className="text-gray-500 mt-4 hover:text-gray-700 font-medium transition-colors duration-200"
      >
        More Priorities
      </button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='xl'
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Select Preferences</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Text>
              We'll start with your budget. How much could you spend on any one measure? Remember, there may be grants or ability to fund some measures through a mortgage if you have one or other lending, so only restrict this if you know you have a fixed figure in mind.
            </Text>

            <br />

            <Flex
              flexWrap="wrap"
              justifyContent="flex-start"
              gap={5}
            >

              {budgetOptions.map((option, index) => (
                <Button
                  key={index}
                  onClick={() => handleChangeBudget(option)}
                  {...ctaButtonStyles}
                  leftIcon={(
                    <HStack
                      color={selectedBudget?.label === option.label ? "black" : theme.colors.green[ 100 ]}
                      background={selectedBudget?.label === option.label ? theme.colors.green[ 100 ] : "black"}
                      height="2em"
                      width="2em"
                      justifyContent="center"
                      alignItems="center"
                      boxShadow="inset -2px 0 0 black"
                    >

                      {selectedBudget?.label === option.label ? <HiOutlineCheck fontSize="1.33em" /> : <AiOutlinePlus fontSize="1.33em" />}
                    </HStack>
                  )}
                >
                  <Text
                    px="1em"
                    flex="1"
                    fontWeight={selectedBudget?.label === option.label ? "medium" : undefined}
                    fontSize='sm'
                  >
                    {option.label}
                  </Text>
                </Button>
              )

              )}

              {/* </Stack> */}
            </Flex>

            <br />

            <Text>And what is your attitude to having building work done to the inside of your property (don't worry, we can arrange it for you if it's needed):
            </Text>

            <br />

            <Flex
              flexWrap="wrap"
              justifyContent="flex-start"
              gap={5}
            >

              {disruptionOptions.map((option, index) => (
                <Button
                  key={index}
                  onClick={() => handleChangeDisruption(option)}
                  {...ctaButtonStyles}
                  leftIcon={(
                    <HStack
                      color={selectedDisruption?.label === option.label ? "black" : theme.colors.green[ 100 ]}
                      background={selectedDisruption?.label === option.label ? theme.colors.green[ 100 ] : "black"}
                      height="3em"
                      width="3em"
                      justifyContent="center"
                      alignItems="center"
                      boxShadow="inset -2px 0 0 black"
                    >

                      {selectedDisruption?.label === option.label ? <HiOutlineCheck fontSize="1.33em" /> : <AiOutlinePlus fontSize="1.33em" />}
                    </HStack>
                  )}
                >
                  <Text
                    px="1em"
                    flex="1"
                    fontWeight={selectedDisruption?.label === option.label ? "medium" : undefined}
                    fontSize='sm'
                  >
                    {option.label}
                  </Text>
                </Button>
              )

              )}

              {/* </Stack> */}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='red'
              mr={3}
              onClick={onClose}
              variant='ghost'
            >
              Close
            </Button>

            <Button
              background="black"
              color='white'
              disabled={!selectedBudget && !selectedDisruption}
              onClick={() => handleSavePreferences()}
            >Save Preferences
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreferencesModal;