import React from "react";
import {
  Box,
  Link as ChakraLink,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Flex,
  VStack,
  Heading
} from "@chakra-ui/react";
import {
  Link as ReactRouterLink,
  useHistory
} from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from "@chakra-ui/icons";
import { Wrapper } from "src/components/shared";
import {
  InlineWidget,
  useCalendlyEventListener
} from "react-calendly";

import theme from "../theme";

const NextSteps: React.FC = () => {
  const [ showScheduleCall, setShowScheduleCall ] = React.useState(false);
  const [ callScheduled, setCallScheduled ] = React.useState(false);
  const history = useHistory();

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: () => {
      setCallScheduled(true);
    }
  });

  return (
    <Wrapper
      variant='narrow'
      px={{
        base: 2,
        md: 4
      }}
      py={{
        base: 4,
        md: 4
      }}
    >
      <ChakraLink
        as={ReactRouterLink}
        to="/footprint"
        marginBottom={5}
        color='#C4C7C5'
        display="inline-block"
      >
        <ChevronLeftIcon />
        Back
      </ChakraLink>

      <Heading
        as="h1"
        fontSize='2xl'
        mb={2}
        textAlign="center"
        color="gray.800"
        fontWeight="semibold"
        textShadow="none"
      >Get The Work Done
      </Heading>

      <Text
        mb={6}
        textAlign="center"
      >There are two ways to take this forward
      </Text>

      <Flex
        direction={{
          base: "column",
          lg: "row"
        }}
        gap={6}
        justify="center"
      >
        {/* Card 2: Talk to Us */}
        <Box
          flex={1}
          borderWidth={2}
          shadow="lg"
          p={12}
          borderColor="transparent"
          borderRadius="md"
          transition="none"
          style={{ borderImage: "linear-gradient(to right, #9B72F2, #88D69E) 1" }}
        >
          <VStack
            align="stretch"
            spacing={4}
            height="100%"
            display="flex"
            justifyContent="space-between"
          >
            <Box>
              <Heading
                as="h2"
                fontSize="lg"
                mb={8}
                textAlign="left"
              >Talk to us for more information
              </Heading>

              <Text textAlign="left">
                If you're not ready for a survey, or you know what you want already, then a call with one of our experts is free, and is without obligation. {" "}

                <ChakraLink
                  as={ReactRouterLink}
                  to="/contact"
                  color='blue'
                  alignSelf="center"
                >
                  Contact Us
                </ChakraLink>

                {" "} or schedule a call below.
              </Text>
            </Box>

            <br />

            {!callScheduled ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Button
                  width="100%"
                  onClick={() => setShowScheduleCall(true)}
                  colorScheme="blue"
                  rightIcon={<ChevronRightIcon />}
                  shadow="lg"
                  _hover={{
                    transition: 200,
                    background: "auto",
                    color: "white",
                    transform: "translateY(-2px)"
                  }}
                  color="gray.100"
                  bg="linear-gradient(135deg, #6aad7d, #6c4ab5);"
                >
                  Schedule a Call
                </Button>

              </Box>
            ) : (
              <Text
                textAlign="center"
                fontWeight="semibold"
              >Call scheduled!
              </Text>
            )}
          </VStack>
        </Box>

        {/* Card 1: In-person Survey */}
        <Box
          flex={1}
          borderWidth={2}
          alignItems="space-between"
          shadow="lg"
          borderRadius="md"
          transition="none"
          style={{ borderImage: "linear-gradient(to left, #9B72F2, #88D69E) 1" }}
          borderColor="linear-gradient(135deg, #9B72F2, #88D69E)"
          p={12}
        >
          <VStack
            align="stretch"
            spacing={4}
          >
            <Box>
              <Heading
                as="h2"
                fontSize="lg"
                mb={8}
                textAlign="left"
              >Get an in-person survey
              </Heading>

              <Text textAlign="left">
                Our partners will survey the property and we'll give you a recommendations report and a call with an expert to talk through it all. <br /> <br /> This can help firm up on these options and potentially explore others, but also give a detailed review of the likely costs and paybacks, and lets you see how you could save money and carbon through up to 30 energy saving and decarbonisation interventions.
              </Text>

              <br />

              <ul style={{ textAlign: "left" }}>
                <li>A survey starts at £95 depending the size of your property and add-ons.</li>

                <li><b>100% refundable against any renewables installation with us.</b> </li>

                <li>We can normally get a survey booked within 1-2 weeks from now if not sooner. Once you've paid you can let us know your date preference – 100% online. </li>
              </ul>

              <br />

            </Box>

            <Flex
              justify="center"
              width="100%"
            >
              <Button
                width="100%"
                onClick={() => history.push("/purchase-survey")}
                colorScheme="blue"
                rightIcon={<ChevronRightIcon />}
                mt={theme.spacing[ 8 ]}
                color="gray.100"
                _hover={{
                  transition: 200,
                  background: "auto",
                  color: "white",
                  transform: "translateY(-2px)"
                }}
                shadow="lg"
                bg="linear-gradient(135deg, #6aad7d, #6c4ab5);"
              >
                Purchase a Survey
              </Button>
            </Flex>
          </VStack>
        </Box>

      </Flex>

      <Modal
        isCentered
        isOpen={showScheduleCall}
        onClose={() => setShowScheduleCall(false)}
        size='6xl'
      >
        <ModalOverlay />

        <ModalContent margin={theme.spacing[ 4 ]}>
          <ModalCloseButton />

          <ModalBody>
            <InlineWidget url="https://calendly.com/dannyatgenous/30min" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Wrapper>
  );
};

export default NextSteps;