import {
  Box,
  HStack,
  Text,
  Link,
  LinkProps
} from "@chakra-ui/react";
import React from "react";
import {
  match,
  Route,
  RouteComponentProps,
  Switch
} from "react-router";
import {
  NavLink,
  NavLinkProps
} from "react-router-dom";
import { FullSpace } from "src/components/shared/FullSpace";
import { Wrapper } from "src/components/shared/Wrapper";
import theme from "src/components/theme";
import { Page } from "src/components/storyblok/Page";
import PartnersLinks from "src/components/partners/PartnersLinks";

import { InstallersPage } from "../installers";
import { ArchitectsPage } from "../architects";
import { LendersPage } from "../lendersV2";

const routes = [
  {
    title: "Partners",
    slug: "/partners"
  },
  {
    title: "Installers",
    slug: "/partners/installers"
  },
  {
    title: "Lenders",
    slug: "/partners/lenders"
  },
  {
    title: "Architects",
    slug: "/partners/architects"
  }
];

export const PartnersPage: React.FC<RouteComponentProps> = () => {
  return (
    <FullSpace
      pt={{
        base: theme.spacing[ 2 ],
        md: theme.spacing[ 20 ]
      }}
    >
      {/* Sticky nav */}

      <Box
        display={{
          base: "none",
          md: "block"
        }}
        role="navigation"
        zIndex={9}
        w="100%"
        borderBottom={{
          base: "2px solid " + theme.colors.gray[ 200 ],
          md: "none"
        }}
        mb={{ md: theme.spacing[ 8 ] }}
        pos={{
          base: "sticky",
          md: "static"
        }}
        top="0"
        bg="white"
      >
        <Wrapper>
          <HStack
            as="ul"
            spacing={{
              base: "1em",
              sm: "2em"
            }}
          >
            {routes.map(({
              title, slug, description
            }) => (
              <Box
                key={formatUrlFriendlyStr(title)}
                textAlign="center"
              >
                <Link
                  as={NavLink}
                  to={slug}
                  isActive={(match: match<Record<string, never>> | null, location: Location) => match?.url === location.pathname}
                  {...linkStyleProps}
                >
                  {title}
                </Link>

                <Text
                  fontSize="sm"
                  color="gray.500"
                >{description}
                </Text>
              </Box>
            ))}

          </HStack>
        </Wrapper>
      </Box>

      {/* Page content */}

      <FullSpace>
        <Switch>
          <Route
            path="/partners"
            exact
            component={PartnersLinks}
          />

          <Route
            path="/partners/installers"
            component={InstallersPage}
          />

          <Route
            path="/partners/architects"
            component={ArchitectsPage}
          />

          <Route
            path="/partners/lenders"
            component={LendersPage}
          />

          <Route component={Page} />
        </Switch>
      </FullSpace>

    </FullSpace>
  );
};

export default PartnersPage;

const linkStyleProps: Partial<LinkProps & NavLinkProps> = {
  py: "1em",
  letterSpacing: "-0.02em",
  fontSize: [ theme.fontSizes.sm, theme.fontSizes.base ],
  _hover: { textDecoration: "none" },
  color: theme.colors.gray[ 400 ],
  activeStyle: {
    color: "black",
    boxShadow: "0 2px 0 black"
  }
};

const formatUrlFriendlyStr = (str: string) => str.replace(/[^a-z0-9\s]/gi, "").replace(/\s+/g, "-").toLowerCase();
